import React, {useEffect, useState} from 'react';
import {
  NotificationType,
  Notification, ListNotificationsQuery,
} from '../API';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { Link } from '@amzn/awsui-components-react';
import {useLocation} from "react-router-dom";
import API, {graphqlOperation, GraphQLResult} from "@aws-amplify/api";
import {listNotifications} from "src/graphql/queries";

export function Notifications() {
  let location = useLocation();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [error, setError] = useState<string>('');

  const loadNotifications = async () => {
    try {
      const listNotificationsResponse = await API.graphql(graphqlOperation(listNotifications, {})) as GraphQLResult<ListNotificationsQuery>;
      const notifications = listNotificationsResponse.data?.listNotifications as Notification[];
      console.debug(`fetchNotifications() notifications is ${JSON.stringify(notifications)}`);
      setNotifications(notifications);
    } catch(error) {
      console.error(`fetchNotifications(): error is ${JSON.stringify(error)}`, { error});
      setError('Error loading notifications.');
    }
  }

  useEffect(() => {
    loadNotifications();
  }, [location]);

  return (
    <div>
      {notifications.length > 0
        &&
          <Flashbar
              items={
                notifications
                  .sort((a,b) => {
                    const aType = a.type === NotificationType.CRITICAL
                      ? 0
                      : a.type === NotificationType.WARNING
                        ? 1
                        : 2;
                    const bType = b.type === NotificationType.CRITICAL
                      ? 0
                      : b.type === NotificationType.WARNING
                        ? 1
                        : 2;
                    return aType < bType ? -1 : 1;
                  })
                  .map((n: Notification, index) => {
                    const content =
                      <>
                        {String(n.message)}
                        {n.links?.map(l =>
                          <>
                            <br/>
                            <Link
                              color='inverted'
                              external href={l!.target}
                              target='_blank'
                            >
                              {l!.text}
                            </Link>
                          </>)}
                      </>;
                    return({
                      content,
                      dismissible: n.dismissible,
                      header: n.type,
                      onDismiss: () => setNotifications([...notifications.filter((_, i) => i !== index)]),
                      type: n.type === NotificationType.CRITICAL
                        ? 'error'
                        : n.type === NotificationType.WARNING
                          ? 'warning'
                          : 'info',
                    })
                  })
              }
              stackItems={notifications.length > 2}
          />}
      {error !== ''
        &&
          <Alert
              dismissible
              onDismiss={() => setError('')}
              type='error'
          >
            {error}
          </Alert>}
    </div>
  );
}