/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const callPACS = /* GraphQL */ `query CallPACS($pacsMethod: String!, $parameters: AWSJSON) {
  callPACS(pacsMethod: $pacsMethod, parameters: $parameters) {
    response
  }
}
` as GeneratedQuery<APITypes.CallPACSQueryVariables, APITypes.CallPACSQuery>;
export const getSiteEvents = /* GraphQL */ `query GetSiteEvents($siteName: String!) {
  getSiteEvents(siteName: $siteName)
}
` as GeneratedQuery<
  APITypes.GetSiteEventsQueryVariables,
  APITypes.GetSiteEventsQuery
>;
export const getKeepSiteDevices = /* GraphQL */ `query GetKeepSiteDevices($input: GreenlightActionInput!) {
  getKeepSiteDevices(input: $input) {
    siteName
    data
  }
}
` as GeneratedQuery<
  APITypes.GetKeepSiteDevicesQueryVariables,
  APITypes.GetKeepSiteDevicesQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications {
  listNotifications {
    dismissible
    links {
      target
      text
    }
    message
    name
    type
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
