/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type GreenlightPublishEventResponse = {
  __typename: "GreenlightPublishEventResponse",
  deviceID?: string | null,
  eventName?: string | null,
  eventReceivedSuccess?: boolean | null,
  greenlightProcessTimestampUTC?: number | null,
  greenlightPublishTimestampUTC?: number | null,
  isCancelled?: boolean | null,
  lastSuccessID?: string | null,
  lastSuccessfulEventTimestampUTC?: number | null,
  lastTestID?: string | null,
  normalizerEventEmployeeLogin?: string | null,
  siteName?: string | null,
  testDurationSeconds?: number | null,
  testEndTimestampUTC?: number | null,
  testStartTimestampUTC?: number | null,
  testSubmittedBy?: string | null,
};

export type GreenlightActionInput = {
  siteName?: string | null,
  data?: string | null,
};

export type GreenlightStartTestResponse = {
  __typename: "GreenlightStartTestResponse",
  siteName?: string | null,
  submitted?:  Array<GreenlightTest | null > | null,
};

export type GreenlightTest = {
  __typename: "GreenlightTest",
  eventName?: string | null,
  deviceID?: string | null,
};

export type GreenlightCancelTestResponse = {
  __typename: "GreenlightCancelTestResponse",
  siteName?: string | null,
  cancelled?:  Array<GreenlightTest | null > | null,
};

export type GreenlightUpdateOverrideResponse = {
  __typename: "GreenlightUpdateOverrideResponse",
  siteName?: string | null,
  markedNA?:  Array<GreenlightTest | null > | null,
  unmarkedNA?:  Array<GreenlightTest | null > | null,
};

export type GreenlightUpdateDeviceNotesResponse = {
  __typename: "GreenlightUpdateDeviceNotesResponse",
  siteName?: string | null,
  submitted?:  Array<GreenlightTest | null > | null,
};

export type PACSDataResponse = {
  __typename: "PACSDataResponse",
  response?: string | null,
};

export type GreenlightKeepLookupResponse = {
  __typename: "GreenlightKeepLookupResponse",
  siteName?: string | null,
  data?: string | null,
};

export type Notification = {
  __typename: "Notification",
  dismissible: boolean,
  links?:  Array<NotificationLink | null > | null,
  message: string,
  name: string,
  type: NotificationType,
};

export type NotificationLink = {
  __typename: "NotificationLink",
  target: string,
  text: string,
};

export enum NotificationType {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFO = "INFO",
}


export type PublishDeviceEventMutationVariables = {
  deviceID: string,
  eventName: string,
  siteName: string,
  data: string,
};

export type PublishDeviceEventMutation = {
  publishDeviceEvent?:  {
    __typename: "GreenlightPublishEventResponse",
    deviceID?: string | null,
    eventName?: string | null,
    eventReceivedSuccess?: boolean | null,
    greenlightProcessTimestampUTC?: number | null,
    greenlightPublishTimestampUTC?: number | null,
    isCancelled?: boolean | null,
    lastSuccessID?: string | null,
    lastSuccessfulEventTimestampUTC?: number | null,
    lastTestID?: string | null,
    normalizerEventEmployeeLogin?: string | null,
    siteName?: string | null,
    testDurationSeconds?: number | null,
    testEndTimestampUTC?: number | null,
    testStartTimestampUTC?: number | null,
    testSubmittedBy?: string | null,
  } | null,
};

export type PublishEventTestMutationVariables = {
  input: GreenlightActionInput,
};

export type PublishEventTestMutation = {
  publishEventTest?:  {
    __typename: "GreenlightStartTestResponse",
    siteName?: string | null,
    submitted?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type CancelEventTestMutationVariables = {
  input: GreenlightActionInput,
};

export type CancelEventTestMutation = {
  cancelEventTest?:  {
    __typename: "GreenlightCancelTestResponse",
    siteName?: string | null,
    cancelled?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateEventOverrideMutationVariables = {
  input: GreenlightActionInput,
};

export type UpdateEventOverrideMutation = {
  updateEventOverride?:  {
    __typename: "GreenlightUpdateOverrideResponse",
    siteName?: string | null,
    markedNA?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
    unmarkedNA?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type UpdateDeviceNotesMutationVariables = {
  input: GreenlightActionInput,
};

export type UpdateDeviceNotesMutation = {
  updateDeviceNotes?:  {
    __typename: "GreenlightUpdateDeviceNotesResponse",
    siteName?: string | null,
    submitted?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type CallPACSQueryVariables = {
  pacsMethod: string,
  parameters?: string | null,
};

export type CallPACSQuery = {
  // Calls to PACS Data API
  callPACS?:  {
    __typename: "PACSDataResponse",
    response?: string | null,
  } | null,
};

export type GetSiteEventsQueryVariables = {
  siteName: string,
};

export type GetSiteEventsQuery = {
  // Grabs events at a specific site
  getSiteEvents?: string | null,
};

export type GetKeepSiteDevicesQueryVariables = {
  input: GreenlightActionInput,
};

export type GetKeepSiteDevicesQuery = {
  // Queries for Keep devices in a site
  getKeepSiteDevices?:  {
    __typename: "GreenlightKeepLookupResponse",
    siteName?: string | null,
    data?: string | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
};

export type ListNotificationsQuery = {
  listNotifications?:  Array< {
    __typename: "Notification",
    dismissible: boolean,
    links?:  Array< {
      __typename: "NotificationLink",
      target: string,
      text: string,
    } | null > | null,
    message: string,
    name: string,
    type: NotificationType,
  } | null > | null,
};

export type OnDeviceEventPublishedSubscriptionVariables = {
  siteName: string,
};

export type OnDeviceEventPublishedSubscription = {
  onDeviceEventPublished?:  {
    __typename: "GreenlightPublishEventResponse",
    deviceID?: string | null,
    eventName?: string | null,
    eventReceivedSuccess?: boolean | null,
    greenlightProcessTimestampUTC?: number | null,
    greenlightPublishTimestampUTC?: number | null,
    isCancelled?: boolean | null,
    lastSuccessID?: string | null,
    lastSuccessfulEventTimestampUTC?: number | null,
    lastTestID?: string | null,
    normalizerEventEmployeeLogin?: string | null,
    siteName?: string | null,
    testDurationSeconds?: number | null,
    testEndTimestampUTC?: number | null,
    testStartTimestampUTC?: number | null,
    testSubmittedBy?: string | null,
  } | null,
};

export type OnDeviceNotesUpdatedSubscriptionVariables = {
  siteName: string,
};

export type OnDeviceNotesUpdatedSubscription = {
  onDeviceNotesUpdated?:  {
    __typename: "GreenlightUpdateDeviceNotesResponse",
    siteName?: string | null,
    submitted?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type OnEventTestStartedSubscriptionVariables = {
  siteName?: string | null,
};

export type OnEventTestStartedSubscription = {
  onEventTestStarted?:  {
    __typename: "GreenlightStartTestResponse",
    siteName?: string | null,
    submitted?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type OnEventTestCancelledSubscriptionVariables = {
  siteName: string,
};

export type OnEventTestCancelledSubscription = {
  onEventTestCancelled?:  {
    __typename: "GreenlightCancelTestResponse",
    siteName?: string | null,
    cancelled?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};

export type OnEventOverrideUpdatedSubscriptionVariables = {
  siteName: string,
};

export type OnEventOverrideUpdatedSubscription = {
  onEventOverrideUpdated?:  {
    __typename: "GreenlightUpdateOverrideResponse",
    siteName?: string | null,
    markedNA?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
    unmarkedNA?:  Array< {
      __typename: "GreenlightTest",
      eventName?: string | null,
      deviceID?: string | null,
    } | null > | null,
  } | null,
};
